import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Gallery } from "./components/Gallery";
import { Roadmap } from "./components/Roadmap";
import { Faq } from "./components/Faq";
import { Footer } from "./components/Footer";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Gallery />
      <Roadmap />
      <Faq />
      <Footer />
    </div>
  );
}

export default App;
