import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Faq = () => {

  return (
    <section className="contact" id="faq">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={2}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={10}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>FAQ'S</h2>
                

                <div className="faq">
        <div className="faq-q">
          <span>
            <b>What Are The French-Kissed Frenchies?</b>
          </span>
          <p>
          The French-Kissed Frenchies NFTs are a collection of one of the most beloved pets in the country. They are presented to the world with an array of styles and features that are sure to fit anyone! 
          </p>
        </div>
        <div className="faq-q">
          <span>
            <b>How Much Will French-Kissed Frenchies Cost?</b>
          </span>
          <p>
          The French-Kissed Frenchies are $100 usd/ 0.0562 ETH, with the exception of the special collections.
          </p>
        </div>
        <div className="faq-q">
          <span>
            <b>How Do I Become A Red Beret Member?</b>
          </span>
          <p>
          You must first join the French-Kissed Frenchies 
          community. Active community contributors and members 
          that own special collection frenchies will automatically 
          become Red Beret members. Any other Red Beret 
          members will be hand-picked by the founders/creators 
          based on specific requirements that are not shared with 
          the public.
          </p>
        </div>
        <div className="faq-q">
          <span>
            <b>What Are The Benefits Of Owning A French-Kissed Frenchy?</b>
          </span>
          <p>
          Owners of French-Kissed Frenchies will have the 
ability to resell their Frenchies for profit, minus a 5% 
royalty fee.
          </p>
        </div>
        <div className="faq-q">
          <span>
            <b>Does French-Kissed Frenchies Make Charitable Donations?</b>
          </span>
          <p>
          2.5% of the royalties from the sell of French-Kissed 
Frenchies will be donated to local French Bulldog Rescues 
and animal shelters.
          </p>
        </div>
      </div>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
